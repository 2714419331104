import { Linking, Platform } from 'react-native'
import {
  isWeb,
  OldUser,
  Angel,
  User,
  Wallet,
  UserLandingScreenResponse,
  LandingScreen,
  UserLanguages,
  IRatings,
  PaginatedResults,
  IReview,
  IReviewDetails,
  UserBookingMetric,
  IdealIssuer
} from '@charlycares/shared'

import { api } from '../api'
import { ApiTags, ResponseData } from '../types'
import { MixedTransaction, WalletTransaction } from '@charlycares/shared'
import { GiveReviewBody, TransactionsOverviewDownloadBody } from './user.dto'

export const userApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: ({ mutation, query }) => ({
    getUserProfile: mutation<ResponseData<OldUser>, void>({
      query: () => ({
        url: 'v1/profile',
        method: 'GET'
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => dispatch({ type: isWeb ? 'set_user' : 'update_profile', payload: data.data }))
          .catch(err => console.log(err))
      }
    }),
    getUser: query<User, void>({
      query: () => ({
        url: 'v2/users',
        method: 'GET'
      }),
      providesTags: [ApiTags.USER],
      transformResponse: ({ data }): User => data
    }),
    updateProfile: mutation<ResponseData<OldUser>, Partial<OldUser> | Partial<Angel> | any>({
      query: body => ({
        url: 'v1/profile',
        method: 'PUT',
        body
      }),
      invalidatesTags: [ApiTags.USER, ApiTags.ANGEL_TODOS, ApiTags.ONBOARDING_SCREENS],
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => dispatch({ type: isWeb ? 'set_user' : 'update_profile', payload: data.data }))
          .catch(err => {
            console.log(err)
          })
      }
    }),
    savePushToken: mutation<void, { token: string; channel: typeof Platform.OS }>({
      query: body => ({
        url: 'v2/push-notification-token',
        method: 'POST',
        body
      })
    }),
    removePushToken: mutation<void, { token: string }>({
      query: body => ({
        url: 'v2/push-notification-token',
        method: 'DELETE',
        body
      }),
      invalidatesTags: [ApiTags.SUBSCRIPTION]
    }),
    getWallets: query<Wallet[], void>({
      query: () => ({
        url: 'v2/users/wallets/',
        method: 'GET'
      }),
      transformResponse: ({ data }): Wallet[] => data
    }),
    getTransactions: query<
      {
        data: MixedTransaction[]
        meta: {
          current_page: number
          from: number
          last_page: number
          per_page: number
          to: number
          total: number
        }
      },
      { page: number }
    >({
      query: ({ page }) => ({
        url: `v2/users/wallets/transactions?page=${page}`,
        method: 'GET'
      })
    }),
    getTransaction: query<WalletTransaction, { transaction_id: string }>({
      query: ({ transaction_id }) => ({
        url: `v2/users/wallets/transactions/${transaction_id}`,
        method: 'GET'
      }),
      transformResponse: ({ data }): WalletTransaction => data
    }),
    getIdealIssuers: query<IdealIssuer[], void>({
      query: () => ({
        url: 'v2/users/wallets/ideal-issuers',
        method: 'GET'
      }),
      transformResponse: ({ data }): IdealIssuer[] => data
    }),
    getLegacyPayment: mutation<WalletTransaction, { payment_id: string }>({
      query: ({ payment_id }) => ({
        url: `v1/payments/single/${payment_id}`,
        method: 'GET'
      }),
      transformResponse: ({ data }): WalletTransaction => data
    }),
    changeLocale: mutation<void, { locale: string }>({
      query: body => ({
        url: 'v1/user/locale',
        method: 'PUT',
        body
      }),
      onQueryStarted(arg, { dispatch }) {
        dispatch({ type: 'set_locale', payload: arg.locale })
      },
      invalidatesTags: [ApiTags.USER, ApiTags.MARKETING]
    }),
    getLanguages: query<UserLanguages, void>({
      query: () => ({
        url: 'v2/users/languages/',
        method: 'GET'
      }),
      transformResponse: ({ data }): UserLanguages => data,
      providesTags: [ApiTags.LANGUAGES]
    }),
    updateLanguages: mutation<void, UserLanguages>({
      query: body => ({
        url: 'v2/users/languages',
        method: 'POST',
        body
      }),
      invalidatesTags: [ApiTags.LANGUAGES, ApiTags.ONBOARDING_SCREENS]
    }),
    getLandingScreens: query<UserLandingScreenResponse, void>({
      query: () => ({
        url: 'v2/users/landing/screens',
        method: 'GET'
      }),
      transformResponse: ({ data, current_screen }): UserLandingScreenResponse => {
        return {
          landing_screens: data,
          current_screen: current_screen as LandingScreen
        }
      },
      providesTags: [ApiTags.ONBOARDING_SCREENS]
    }),
    getUserRatings: query<IRatings, { user_id?: string }>({
      query: ({ user_id }) => ({
        url: `v2/users/ratings${user_id ? `/${user_id}` : ''}`,
        method: 'GET'
      }),
      transformResponse: ({ data }): IRatings => data,
      providesTags: [ApiTags.REVIEWS]
    }),
    getReviewDetails: query<IReviewDetails, { ratingId: string }>({
      query: ({ ratingId }) => ({
        url: `v2/ratings/${ratingId}`,
        method: 'GET'
      }),
      providesTags: (result, error, arg) => [{ type: ApiTags.REVIEW, id: arg.ratingId }],
      transformResponse: ({ data }): IReviewDetails => data
    }),
    getAllReviews: query<PaginatedResults<IReview>, { user_id?: string; page?: number }>({
      query: ({ user_id, page }) => ({
        url: `v2/users/ratings/all${user_id ? `/${user_id}` : ''}${page ? `?page=${page}` : ''}`,
        method: 'GET'
      })
    }),
    giveReview: mutation<void, GiveReviewBody & { booking_id: string }>({
      query: ({ booking_id, ...body }) => ({
        url: `v2/bookings/${booking_id}/review`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: ApiTags.REVIEW, id: arg.booking_id }, ApiTags.REVIEWS]
    }),
    getRatingsCategories: query<string[], { user_id?: string }>({
      query: ({ user_id }) => ({
        url: `v2/users/ratings-categories/${user_id ? `/${user_id}` : ''}`,
        method: 'GET'
      }),
      transformResponse: ({ data }): string[] => data
    }),
    getFamilyReviews: query<PaginatedResults<IReview>, void>({
      query: () => ({
        url: `v2/users/ratings/rated-bookings`,
        method: 'GET'
      })
    }),
    dismissRating: mutation<void, { booking_id: string }>({
      query: ({ booking_id }) => ({
        url: `v2/bookings/${booking_id}/rating/dismiss`,
        method: 'POST'
      })
    }),
    getUserBookingsMetric: query<UserBookingMetric, void>({
      query: () => ({
        url: 'v2/users/bookings-metric',
        method: 'GET'
      }),
      transformResponse: ({ data }): UserBookingMetric => data
    }),
    getProfileReviews: query<PaginatedResults<IReview>, { userId?: string; page?: number }>({
      query: ({ userId, page }) => ({
        url: `v2/users/ratings/all${userId ? `/${userId}` : ''}?page=${page ?? '1'}`,
        method: 'GET'
      })
    }),
    getUserIndicators: query<PaginatedResults<IReview>, void>({
      query: () => ({
        url: `v1/indicators`,
        method: 'GET'
      }),
      onQueryStarted(arg, { queryFulfilled, dispatch }) {
        queryFulfilled
          .then(({ data }) => {
            // @ts-ignore
            if (data.data.active_timer) {
              dispatch(api.util.invalidateTags([ApiTags.BOOKING_TIMER]))
            }

            dispatch(api.util.invalidateTags([ApiTags.ANGEL_TODOS]))

            return dispatch({ type: 'set_indicators', payload: data.data })
          })
          .catch(err => console.log(err))
      }
    }),
    getTransactionsOverviewLink: mutation<{ transactions_overview_link: string }, TransactionsOverviewDownloadBody>({
      query: body => ({
        url: `v2/users/get-transactions-overview-link`,
        method: 'POST',
        body
      }),
      onQueryStarted(arg, { queryFulfilled }) {
        queryFulfilled.then(({ data }) => Linking.openURL(data?.transactions_overview_link))
      }
    })
  })
})

export const {
  useGetUserProfileMutation,
  useSavePushTokenMutation,
  useRemovePushTokenMutation,
  useUpdateProfileMutation,
  useGetWalletsQuery,
  useGetTransactionsQuery,
  useGetTransactionQuery,
  useGetLegacyPaymentMutation,
  useGetUserQuery,
  useChangeLocaleMutation,
  useGetLanguagesQuery,
  useUpdateLanguagesMutation,
  useGetLandingScreensQuery,
  useGetUserRatingsQuery,
  useGetAllReviewsQuery,
  useGetReviewDetailsQuery,
  useGiveReviewMutation,
  useGetRatingsCategoriesQuery,
  useDismissRatingMutation,
  useGetUserBookingsMetricQuery,
  useGetProfileReviewsQuery,
  useGetFamilyReviewsQuery,
  useGetUserIndicatorsQuery,
  useGetIdealIssuersQuery,
  useGetTransactionsOverviewLinkMutation
} = userApi
