import React, { useEffect } from 'react'
import { useRouter } from '@charlycares/shared'
import { Box, Flex, HStack, Pressable, Spacer, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { CharlyIcon, Ideal, LandingLayout } from '@charlycares/ui'
import { usePayBookingMutation } from '@charlycares/data-access'
import { Linking } from 'react-native'

const PayBookingFailedScreen = () => {
  const { t } = useTranslation()
  const { getParam, navigate } = useRouter()
  const bookingId = getParam('bookingId')
  const [payBooking, { isLoading: isPaying, isSuccess: isPayBookingSuccess, data: payBookingData }] =
    usePayBookingMutation()

  console.log(bookingId)

  useEffect(() => {
    if (isPayBookingSuccess && payBookingData && 'redirect_url' in payBookingData && payBookingData?.redirect_url) {
      Linking.openURL(payBookingData.redirect_url)

      return
    }

    if (isPayBookingSuccess && bookingId && payBookingData && 'is_booked_before' in payBookingData) {
      navigate('BookingFeedback', '/booking/feedback', { bookingId, isBookedBefore: payBookingData.is_booked_before })
    }
  }, [bookingId, isPayBookingSuccess, navigate, payBookingData])

  return (
    <LandingLayout withLogo>
      <Flex justifyContent="center" height="full" px="20px">
        <Box>
          <HStack justifyItems="center">
            <CharlyIcon name="nav-close" color="alert.danger.400" size={20} pt="5px" />
            <Text color="white" fontWeight="600" fontSize="18" ml="8px">
              {t('payBookingFailedScreen.failedTitle')}
            </Text>
          </HStack>
          <Pressable onPress={() => Linking.openURL('whatsapp://send?phone=+31202102323')}>
            <Text color="white" fontSize="16px" mt="10px">
              {t('payBookingFailedScreen.failedText')}
            </Text>
          </Pressable>
        </Box>

        <Box bgColor="secondary.400" rounded="md" p="10px" mt="40px">
          <HStack justifyItems="center">
            <Text color="white" fontWeight="600" fontSize="18">
              {t('payBookingFailedScreen.helpTitle')}
            </Text>
            <Spacer />
            <CharlyIcon name="icn-block" color="alert.danger.400" size="30" mr="10px" />
          </HStack>
          <Text color="white" fontSize="16px" mt="10px">
            {t('payBookingFailedScreen.helpText')}
          </Text>
        </Box>

        <Ideal
          mt="170px"
          onChange={value => payBooking({ booking_id: bookingId, issuer: value, payment_method: 'ideal' })}
          isLoading={isPaying}
        />
      </Flex>
    </LandingLayout>
  )
}

export default PayBookingFailedScreen

PayBookingFailedScreen.navigationOptions = {
  headerShown: false
}
