import React from 'react'
import { Box, HStack, Text, Select, IBoxProps, Skeleton } from 'native-base'
import { CharlyIcon, SvgUri } from '../icons'
import { useGetIdealIssuersQuery } from '@charlycares/data-access'
import { useTranslation } from 'react-i18next'

interface Props extends IBoxProps {
  onChange: (value: string) => void
  isLoading: boolean
  disabled?: boolean
}

const Ideal = ({ onChange, isLoading, disabled, ...boxProps }: Props) => {
  const { t } = useTranslation()
  const { data: issuers } = useGetIdealIssuersQuery()

  return (
    <Box rounded="md" bgColor="white" width="100%" px="15px" py="10px" {...boxProps} shadow="3">
      <HStack>
        <CharlyIcon name="ideal" size="32px" />
        <Text lineHeight="30px" ml="8px" fontSize="16px" color="text.dark.400" fontWeight="600">
          iDeal
        </Text>
        <Text lineHeight="30px" ml="4px" fontSize="14px" color="text.dark.400">
          {t('ideal.mostUsed')}
        </Text>
      </HStack>
      {isLoading && (
        <Skeleton height="50px" startColor="gray.alpha900.10" endColor="white" width="100%" rounded="md" mt="10px" />
      )}
      {!isLoading && (
        <Select
          onValueChange={value => onChange(value)}
          isDisabled={disabled}
          mt="10px"
          placeholder={t('ideal.chooseBank')}
          bgColor="gray.50"
          rounded="md"
          borderWidth="1px"
          placeholderTextColor="gray.900"
          height={50}
          _actionSheetContent={{
            bgColor: 'white'
          }}
          _actionSheetBody={{
            bgColor: 'white'
          }}
          _item={{
            bgColor: 'white'
          }}
          dropdownIcon={<CharlyIcon name="icn-caret-down" size="24px" color="gray.700" />}
        >
          {issuers?.map(issuer => (
            <Select.Item
              key={issuer.id}
              label={issuer.name}
              value={issuer.id}
              leftIcon={
                <Box mt="-10px" width="40px">
                  <SvgUri uri={issuer.image} size="40px" />
                </Box>
              }
            />
          ))}
        </Select>
      )}
    </Box>
  )
}

export default Ideal
