import React, { useEffect, useState } from 'react'
import { formatMoney, Money, useRouter, WalletTransaction } from '@charlycares/shared'
import { Box, Flex, HStack, Pressable, Skeleton, Text } from 'native-base'
import { useTranslation } from 'react-i18next'
import { Cash, Ideal, LandingLayout, SepaDirectDebit } from '@charlycares/ui'
import { useGetPaymentSummaryMutation, usePayBookingMutation } from '@charlycares/data-access'
import Svg, { Path } from 'react-native-svg'
import { Linking } from 'react-native'

const PayBookingScreen = () => {
  const { t } = useTranslation()
  const { getParam, navigate } = useRouter()
  const bookingId = getParam('bookingId')
  const [getPaymentSummaryData, { isLoading, data: bookingPaymentSummary }] = useGetPaymentSummaryMutation()
  const [payBooking, { isLoading: isPaying, isSuccess: isPayBookingSuccess, data: payBookingData }] =
    usePayBookingMutation()

  useEffect(() => {
    getPaymentSummaryData({ booking_id: bookingId })
  }, [getPaymentSummaryData, bookingId])

  useEffect(() => {
    if (isPayBookingSuccess && payBookingData && 'redirect_url' in payBookingData && payBookingData?.redirect_url) {
      Linking.openURL(payBookingData.redirect_url)

      return
    }

    if (isPayBookingSuccess && bookingId && payBookingData && 'is_booked_before' in payBookingData) {
      navigate('BookingFeedback', '/booking/feedback', {
        bookingId,
        isBookedBefore: payBookingData.is_booked_before,
        paymentMethod
      })
    }
  }, [bookingId, isPayBookingSuccess, navigate, payBookingData])

  const cashEnabled =
    bookingPaymentSummary?.wallet_transactions?.reduce((carry, walletTransaction): number => {
      return (walletTransaction.used_saldo as Money).amount + carry
    }, 0) === 0

  const [paymentMethod, setPaymentMethod] = useState<'ideal' | 'cash' | 'sepadirectdebit' | ''>('')
  const pay = async (bookingId: string, method: 'ideal' | 'cash' | 'sepadirectdebit', issuer?: string) => {
    setPaymentMethod(method)
    payBooking({ booking_id: bookingId, payment_method: method, issuer: issuer })
  }

  return (
    <LandingLayout
      headerTitle={t('payBookingScreen.headerTitle')}
      headerRight={() => (
        <Text color="white" fontSize="22px">
          {isLoading && <Skeleton rounded="full" w="50px" h="33px" startColor="secondary.400" />}
          {!isLoading && formatMoney(bookingPaymentSummary?.amount_to_pay)}
        </Text>
      )}
    >
      <Flex justifyContent="center" height="full" px="20px" mt="24px">
        <Box>
          <HStack>
            <Svg width="41" height="32" fill="none">
              <Path
                fill="#C1F2E1"
                d="M22.0387 14.1533c.4792-2.4446 1.6863-3.6668 3.6213-3.6668.7825 0 1.6773.2608 2.6842.7825l.646-1.2648c-1.0008-.58839-2.1109-.88258-3.3302-.88258-1.4376 0-2.5992.44281-3.4849 1.32848-.8856.8856-1.4467 2.12-1.6833 3.7032h-1.4922v1.1738h1.383l-.0182.7279v.4004l.0182.3821h-1.383v1.1738H20.51c.2791 1.5589.8568 2.7463 1.7334 3.5622.8765.8159 2.0336 1.2238 3.4712 1.2238 1.0373 0 1.9988-.1971 2.8844-.5914V20.841c-.9281.4004-1.8653.6006-2.8116.6006-1.0312 0-1.8546-.2988-2.4703-.8963-.6157-.5975-1.0358-1.4421-1.2602-2.534h4.0672v-1.1738h-4.2128l-.0182-.4094v-.5824l.0182-.5186h4.7678v-1.1738h-4.6404Z"
              />
              <Path
                fill="#C1F2E1"
                fillRule="evenodd"
                d="M24.9996 2.34998c-7.5387 0-13.65 6.11131-13.65 13.65002 0 7.5387 6.1113 13.65 13.65 13.65s13.65-6.1113 13.65-13.65c0-7.53871-6.1113-13.65002-13.65-13.65002ZM12.6496 16c0-6.82074 5.5293-12.35002 12.35-12.35002 6.8207 0 12.35 5.52928 12.35 12.35002 0 6.8207-5.5293 12.35-12.35 12.35-6.8207 0-12.35-5.5293-12.35-12.35Z"
                clipRule="evenodd"
              />
              <Path stroke="#C1F2E1" d="M6 9.5h8m-8 13h8m-14-6h12" />
            </Svg>
            <Text color="alert.success.300" fontSize="18px" fontWeight="600" ml="15px">
              {t('payBookingScreen.quickPayout.title')}
            </Text>
          </HStack>
          <Text mt="10px" mb="10px" color="white" fontSize="14px">
            {t('payBookingScreen.quickPayout.description')}
          </Text>
          <Ideal
            mt="15px"
            isLoading={isPaying && paymentMethod === 'ideal'}
            disabled={isPaying && paymentMethod !== 'ideal'}
            onChange={value => pay(bookingId, 'ideal', value)}
          />
          {isLoading && (
            <Box height="170px">
              <Skeleton
                mt="15px"
                width="100%"
                height="50px"
                rounded="md"
                startColor="secondary.400"
                endColor="secondary.alpha.10"
              />
            </Box>
          )}
          {!isLoading && (
            <Cash
              mt="15px"
              amount={formatMoney(bookingPaymentSummary?.rate.angel_payout)}
              onComplete={() => pay(bookingId, 'cash')}
              isLoading={isPaying && paymentMethod === 'cash'}
              enabled={cashEnabled}
              disabled={isPaying && paymentMethod !== 'cash'}
            />
          )}

          <Text color="white" fontWeight="600" fontSize="16px" mt="60px" mb="10px">
            {t('payBookingScreen.sepaDirectDebit.title')}
          </Text>
          <Pressable
            onPress={() => pay(bookingId, 'sepadirectdebit')}
            isDisabled={isPaying && paymentMethod !== 'sepadirectdebit'}
            _disabled={{
              opacity: 0.9
            }}
          >
            <SepaDirectDebit loading={isPaying && paymentMethod === 'sepadirectdebit'} />
          </Pressable>
        </Box>
      </Flex>
    </LandingLayout>
  )
}

export default PayBookingScreen

PayBookingScreen.navigationOptions = {
  headerShown: false
}
